<template>
  <div>
    <div
      class="keith"
      style="
        width: 100%;
        height: 35px;
        background-color: #efeef0;
        border-bottom: 1px solid #c7c6c7;
        position: relative;
      "
    >
      <ul
        style="
          width: 94%;
          height: 35px;
          box-sizing: border-box;
          overflow-x: auto;
          overflow-y: hidden;
        "
        ref="scrollContent"
      >
        <li :class="{ tabLiT: tabLiClass == '桌面' }" @click="desktopClick">
          <span>我的桌面</span>
        </li>
        <li
          v-for="(tab, index) in tabArr"
          :key="index"
          @click="tabClick(tab, index)"
          :class="{ tabLiT: tab == tabLiClass }"
          style="white-space: nowrap"
        >
          <span>{{ tab }}</span>
          <img
            @click.stop="tabClose(tab, index)"
            class="li_img"
            src="../../assets/mianAssets/关闭.png"
            alt=""
          />
        </li>
      </ul>

      <div
        style="
          position: absolute;
          top: 0;
          right: 0;
          width: 6%;
          height: 35px;
          background-color: pink;
          display:flex;
        "
      >
      <div style="border-right: 1px solid;flex: 1;" class="flex" @click="handleLast"><i class="el-icon-arrow-left"></i></div>
      <div style="flex: 1;" class="flex" @click="handleNext"><i class="el-icon-arrow-right"></i></div>
    </div>
    </div>
  </div>
</template>

<script>
import eventBus from "../../until/bus.js";

export default {
  name: "mineDesktop",
  props: ["aside_tab_Arr"],
  data() {
    return {
      tabLiClass: "桌面",
      tabArr: [],
      scrollIndex: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      eventBus.$on("aside_tab_Class", (data) => {
        this.tabLiClass = data;
      });
    });
  },
  computed: {},
  methods: {
    handleNext() {
      this.$refs.scrollContent.scrollLeft += 75;
    },
    handleLast(){
      this.$refs.scrollContent.scrollLeft -= 75;
    },
    desktopClick() {
      this.tabLiClass = "桌面";
      this.$emit("tab_main_content_desktop", "桌面");
      eventBus.$emit("tab_aside_item", "-1");
    },
    tabClick(val) {
      this.$emit("tab_main_content", val);
      this.tabLiClass = val;
      eventBus.$emit("tab_aside_item", val);
    },
    tabClose(val, index) {
      this.tabArr.splice(index, 1);
      if (val == this.tabLiClass && index == this.tabArr.length) {
        this.$emit("tab_main_close", this.tabArr[index - 1]);
        this.tabLiClass = this.tabArr[index - 1];
        eventBus.$emit("tab_aside_activeNum", this.tabArr[index - 1]);
        if (this.tabArr.length == 0) {
          this.tabLiClass = "桌面";
          eventBus.$emit("tab_aside_activeNum", "-1");
        }
      } else {
        this.$emit("tab_main_close", this.tabArr[this.tabArr.length - 1]);
        this.tabLiClass = this.tabArr[this.tabArr.length - 1];
        eventBus.$emit(
          "tab_aside_activeNum",
          this.tabArr[this.tabArr.length - 1]
        );
      }
    },
  },
  watch: {
    aside_tab_Arr(val) {
      if (this.tabArr.indexOf(val) === -1) {
        this.$set(this.tabArr, this.tabArr.length, val);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.keith ul {
  margin-left: 6px;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    text-align: cen;
    padding: 0 30px;
    color: #282828;
    perspective: 150px;
    cursor: pointer;
    transition: all 0.5s;

    .li_img {
      width: 10px;
      height: 10px;
      position: absolute;
      right: 10px;
    }

    .li_img:hover {
      content: url(../../assets/mianAssets/关闭小.png);
      width: 15px;
      height: 15px;
      position: absolute;
      right: 8px;
    }
  }

  li::after {
    content: "";
    font-family: element-icons;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    right: 0;
    z-index: -1;
    border-start-start-radius: 5px;
    border-start-end-radius: 5px;
    background-color: #d0cfd1;
    border: 1px solid #a0a0a0;
    transform: rotateX(45deg);
  }

  .tabLiT::after {
    content: "";
    background-color: #ededee;
    border-bottom: 0;
  }
}
.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
