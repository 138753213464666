
// import Vue from "vue";
// import App from "./App.vue";
// import "/src/App.css";
// import VueRouter from "vue-router";
// import store from "./store";
// import animate from "animate.css";
// import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
// import locale from "element-ui/lib/locale/lang/zh-CN";

// import Avue from "@smallwei/avue";
// import "@smallwei/avue/lib/index.css";
// import AvueMap from "avue-plugin-map";
// import { Message } from "element-ui";
// Vue.use(AvueMap);

// import axios from "axios";
// axios.defaults.baseURL="http://192.168.3.3/index.php/api"

// import "./assets/icon/iconfont.css";

// import searchCase from "/src/components/main/c-searchCase/searchCase.vue";
// Vue.component("search-case", searchCase);
// import breadCrumb from "/src/components/main/c-breadCrumb/breadCrumb.vue";
// Vue.component("bread-crumb", breadCrumb);

// const server = axios.create({
//   timeout: 5000, // 请求超时时间
// });
// Vue.prototype.$http = server;
// server.interceptors.request.use((config) => {
//   if (localStorage.getItem("token")) {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers["token"] = token;
//     }
//     return config;
//   } else {
//     const token = sessionStorage.getItem("token");
//     if (token) {
//       config.headers["token"] = token;
//     }
//     return config;
//   }
// });
// server.interceptors.response.use(
//   (res) => {
//     if (res.data.code == 401 && res.data.msg == "token过期") {
//       Message({
//         type: "error",
//         message: "登录过期，请重新登录",
//       });
//       localStorage.removeItem("token");
//       localStorage.removeItem("commercial_id");
//       window.location.reload();
//     }
//     return res;
//   },
//   (err) => {
//     Message({
//       type: "error",
//       message: "连接服务器失败",
//     });
//     console.log("err:", err);
//     return err;
//   }
// );
// import pageInfo from "/src/until/pageInfo";
// Vue.prototype.$pageInfoTil = pageInfo;
// import { VueJsonp } from "vue-jsonp";
// Vue.use(VueJsonp);
// import qs from "qs";
// Vue.prototype.$qs = qs;

// Vue.use(Avue, { axios });

// Vue.config.productionTip = false;

// Vue.use(ElementUI, { size: "small", zIndex: 3000, locale });

// Vue.use(animate);

// // Vue.use(VueRouter);

// new Vue({
//   VueRouter,
//   store,
//   render: (h) => h(App),
// }).$mount("#app");




import Vue from "vue";
import App from "./App.vue";
import "/src/App.css";
import VueRouter from "vue-router";
import store from "./store";
import animate from "animate.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/zh-CN";

import Avue from "@smallwei/avue";
import "@smallwei/avue/lib/index.css";
import AvueMap from "avue-plugin-map";
import { Message } from "element-ui";
Vue.use(AvueMap);

import axios from "axios";
axios.defaults.baseURL="https://api.txxsy.com/index.php/api"

import "./assets/icon/iconfont.css";

import searchCase from "/src/components/main/c-searchCase/searchCase.vue";
Vue.component("search-case", searchCase);
import breadCrumb from "/src/components/main/c-breadCrumb/breadCrumb.vue";
Vue.component("bread-crumb", breadCrumb);

const server = axios.create({
  timeout: 5000, // 请求超时时间
});
Vue.prototype.$http = server;
server.interceptors.request.use((config) => {
  // console.log(config.url);
  if(config.url)
  if(/(\/[a-zA-Z_0-9]+){3}/gs.test(config.url)){
    config.url=config.url.replace(/\/[a-zA-Z_0-9]+/,'')
  }
  if (localStorage.getItem("token")) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  } else {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  }
});
server.interceptors.response.use(
  (res) => {
    if (res.data.code == 401 && res.data.msg == "token过期") {
      Message({
        type: "error",
        message: "登录过期，请重新登录",
      });
      localStorage.removeItem("token");
      localStorage.removeItem("commercial_id");
      window.location.reload();
    }
    return res;
  },
  (err) => {
    Message({
      type: "error",
      message: "连接服务器失败",
    });
    console.log("err:", err);
    return err;
  }
);
import pageInfo from "/src/until/pageInfo";
Vue.prototype.$pageInfoTil = pageInfo;
import { VueJsonp } from "vue-jsonp";
Vue.use(VueJsonp);
import qs from "qs";
Vue.prototype.$qs = qs;

Vue.use(Avue, { axios });

Vue.config.productionTip = false;

Vue.use(ElementUI, { size: "small", zIndex: 3000, locale });

Vue.use(animate);

// Vue.use(VueRouter);

new Vue({
  VueRouter,
  store,
  render: (h) => h(App),
}).$mount("#app");

