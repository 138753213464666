<template>
  <div class="searchcase">
    <el-row>
      <el-col :span="6" v-if="'date' in searchData">
        <span>日期范围：</span>
        <el-date-picker v-model="inquire.dateRange" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @clear="clearDateRange">
        </el-date-picker>
      </el-col>
      <el-col :span="1.5" v-if="'firstSelectSpan' in searchData">
        <span>{{ searchData.firstSelectSpan }}</span>
      </el-col>
      <el-col :span="2" v-if="'isFirstSelect' in searchData">
        <el-select v-model="inquire.firstSelect" clearable filterable :placeholder="searchData.firstSelectPlaceholder">
          <el-option v-for="item in searchData.firstOptiones" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" v-if="'optionLongName1' in searchData">
        <el-select v-model="inquire.optionLongName1" clearable filterable :placeholder="searchData.selectLongPlace1">
          <el-option v-for="item in searchData.optionLongNamees1" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="1.5" v-if="'secondSelectSpan' in searchData">
        <span>{{ searchData.secondSelectSpan }}</span>
      </el-col>
      <el-col :span="2" v-if="'isSecondSelect' in searchData">
        <el-select v-model="inquire.secondSelect" clearable filterable :placeholder="searchData.secondSelectPlaceholder">
          <el-option v-for="item in searchData.secondOptiones" :key="item.id" :label="item.shop_name" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="1.5" v-if="'thirdSelectSpan' in searchData">
        <span>{{ searchData.thirdSelectSpan }}</span>
      </el-col>
      <el-col :span="2" v-if="'isThirdSelect' in searchData">
        <el-select v-model="inquire.thirdSelect" clearable filterable :placeholder="searchData.thirdSelectPlaceholder">
          <el-option v-for="item in searchData.thirdSelectOptiones" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4" v-if="'isInquireValue' in searchData">
        <el-input v-model="inquire.inquireValue" :placeholder="searchData.inquirePlaceholder" clearable></el-input>
      </el-col>
      <el-col :span="1.5" v-if="'isInquireBtn' in searchData">
        <el-button type="success" size="mini" @click="inquireBtnClick">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_inquire"></span>
            <span style="margin-left: 5px">查询</span>
          </div>
        </el-button>
      </el-col>
      <el-col :span="1.5" v-if="'searchBtn2' in searchData">
        <el-button type="primary" size="mini">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_daochu"></span>
            <span style="margin-left: 5px">导出</span>
          </div>
        </el-button>
      </el-col>
      <el-col :span="1.5" v-if="'isDaoRuBtn' in searchData">
        <el-button type="primary" size="mini">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_daoru"></span>
            <span style="margin-left: 5px">导入</span>
          </div>
        </el-button>
      </el-col>
      <el-col :span="1.5" v-if="'isConfirm' in searchData">
        <el-button type="primary" size="mini">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_add"></span>
            <span style="margin-left: 5px">确认</span>
          </div>
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    searchData: {
      type: Object,
    },
  },
  data() {
    return {
      inquire: {
        dateRange: [],
        firstSelect: "",
        secondSelect: "",
        thirdSelect: "",
        inquireValue: "",
        optionLongName1: "",
      },
    };
  },
  mounted() { },
  computed: {},
  methods: {
    // 时间
    clearDateRange() {
      // 清空日期范围时将数组置为空数组
      this.inquire.dateRange = null;
    },
    inquireBtnClick() {
      if (this.inquire.dateRange === null) {
        // dateRange为空，给出提示或者不执行代码逻辑
        this.inquire.dateRange = [];
      }
      this.$emit("inquireBtnClick", this.inquire);
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.searchcase {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
  min-height: 32px;
}
</style>
