const chooseModule = {
  namespaced: true,
  state: {
    value: "",
    page: "",
    indexKey: 1,
    row: "",
  },
  mutations: {
    onChoose(state, payload) {
      state.value = payload.chooseModule;
      state.indexKey += 1;
      if (payload.title) {
        state.page = payload.title;
      }
      if (payload.row) {
        state.row = payload.row;
      }
    },
    deleteRow(state) {
      state.row = "";
    },
  },
};

export default chooseModule;
