<template>
  <div id="app">
    <div v-if="!isLogin" style="width: 100vw; height: 100vh; background-color: aquamarine">
      <login @loginT="loginT"></login>
    </div>
    <el-container v-if="isLogin">
      <el-header><header-vue></header-vue></el-header>
      <el-container class="container">
        <el-aside class="aside" width="200px">
          <div style="
              width: 200px;
              height: 100%;
              position: absolute;
              background-color: #eeeeee;
            "></div>
          <aside-vue @asideItemClick="asideItemClick"></aside-vue>
        </el-aside>
        <el-main class="main">
          <main-tab-vue :aside_tab_Arr="this.tabName" @tab_main_content="tabContent" @tab_main_close="tabClose"
            @tab_main_content_desktop="tabContentDesk"></main-tab-vue>
          <keep-alive>
            <component :is="myMains"></component>
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import bus from "./until/bus.js";
import asideVue from "./components/asideVue.vue";
import headerVue from "./components/headerVue.vue";
import mainTabVue from "./components/main/mainTabVue.vue";

export default {
  name: "App",
  // 《导入页面》
  components: {
    headerVue,
    asideVue,
    login: () => import("./components/login.vue"),
    mainTabVue,
    // 我的桌面
    desktopVue: () => import("./components/main/desktopVue.vue"),
    // 商品分类
    goodsCategoryVue: () =>
      import(
        "./components/main/initialValue1/label1_goodsCategory/goodsCategoryVue.vue"
      ),
    // 品牌类型
    brandTypeVue: () =>
      import(
        "./components/main/initialValue1/label2_brandType/brandTypeMain.vue"
      ),
    // 商品档案
    CommodityFileVue: () =>
      import(
        "./components/main/initialValue1/label3_commodityFile/CommodityFileVue.vue"
      ),
    // 水票管理
    waterRegulateVue: () =>
      import(
        "./components/main/initialValue1/label4_waterRegulate/waterRegulateVue.vue"
      ),
    // 互动营销
    interactiveVue: () =>
      import("./components/main/initialValue1/label5_interActive/index.vue"),
    // 全场活动
    overallActive: () =>
      import(
        "./components/main/initialValue1/label6_overallActive/overallActive.vue"
      ),
    // 单品活动
    singleItem: () =>
      import(
        "./components/main/initialValue1/label7_singleItem/singleItem.vue"
      ),
    // 优惠套餐
    discountPackage: () =>
      import(
        "./components/main/initialValue1/label8_discountPackage/discountPackage.vue"
      ),
    // 优惠券
    discountCoupon: () =>
      import(
        "./components/main/initialValue1/label9_discountCoupon/discountCoupon.vue"
      ),
    // 二维码
    QRcode: () =>
      import("./components/main/initialValue1/label10_QRcode/QRcode.vue"),
    //支付宝电子水票
    Alipay: () =>
      import("./components/main/initialValue1/label11_Alipay/Alipay.vue"),

    // 自提空桶
    emptyBarrel: () =>
      import(
        "./components/main/initialValue2/label1_emptyBarrel/label1_emptyBarrel.vue"
      ),
    // 水站进货订单
    waterStationPurchase: () =>
      import(
        "./components/main/initialValue2/label2_waterStationPurchase/waterStationPurchase.vue"
      ),
    // 预售产品库存审核
    inventoryAudit: () =>
      import(
        "./components/main/initialValue2/label3_inventoryAudit/inventoryAudit.vue"
      ),
    // 水站资产管理
    waterAtationAssets: () =>
      import(
        "./components/main/initialValue2/label4_waterAtationAssets/waterAtationAssets.vue"
      ),
    // 其他收款记录
    otherCollectionRecords: () =>
      import(
        "./components/main/initialValue2/label5_otherCollectionRecords/otherCollectionRecords.vue"
      ),
    // 水站进货统计表
    waterStationPST: () =>
      import(
        "./components/main/initialValue2/label6_waterStationPST/waterStationPST.vue"
      ),
    // 水站进货明细表
    waterStationPL: () =>
      import(
        "./components/main/initialValue2/label7_waterStationPL/waterStationPL.vue"
      ),
    // 水站水桶统计表
    waterStationBST: () =>
      import(
        "./components/main/initialValue2/label8_waterStationBST/waterStationBST.vue"
      ),
    // 水站销售月报表
    waterStationMSS: () =>
      import(
        "/src/components/main/initialValue2/label9_waterStationMSS/waterStationMSS"
      ),
    // 预购库存统计表
    advancePurchaseIST: () =>
      import(
        "./components/main/initialValue2/label10_advancePurchaseIST/advancePurchaseIST.vue"
      ),
    // 商品销售统计表
    commodityMST: () =>
      import(
        "./components/main/initialValue2/label11_commodityMST/commodityMST.vue"
      ),
    // 空桶统计表
    emptyBarrelPST: () =>
      import(
        "./components/main/initialValue2/label12_emptyBarrelPST/emptyBarrelPST.vue"
      ),
    // 收款统计表
    proceedsPST: () =>
      import(
        "./components/main/initialValue2/label13_proceedsPST/proceedsPST.vue"
      ),
    // 收款月报表
    proceedsMRF: () =>
      import(
        "./components/main/initialValue2/label14_proceedsMRF/proceedsMRF.vue"
      ),
    // 司机配送统计表
    driverDistributionPST: () =>
      import(
        "./components/main/initialValue2/label15_driverDistributionPST/driverDistributionPST.vue"
      ),
    // 司机配送明细表
    driverDistributionPL: () =>
      import(
        "./components/main/initialValue2/label16_driverDistributionPL/driverDistributionPL.vue"
      ),
    // 呼叫记录
    callLog: () =>
      import("./components/main/initialValue3/label1_callLog/callLog.vue"),
    // 人工报单
    manualEntry: () =>
      import(
        "./components/main/initialValue3/label2_manualEntry/manualEntry.vue"
      ),
    // 销售订单
    sellOrder: () =>
      import("./components/main/initialValue3/label3_sellOrder/sellOrder.vue"),
    // 出库单
    srockOut: () =>
      import("./components/main/initialValue3/label4_stockOut/stockOut.vue"),
    // 退桶单
    bucketReturn: () =>
      import(
        "./components/main/initialValue3/label5_bucketReturn/bucketReturn.vue"
      ),
    // 收款记录
    collectionRecord: () =>
      import(
        "./components/main/initialValue4/label1_collectionRecord/collectionRecord.vue"
      ),
    // 退款记录
    refundRecord: () =>
      import(
        "./components/main/initialValue4/label2_refundRecord/refundRecord.vue"
      ),
    // 退现记录
    withdrawalRecord: () =>
      import(
        "./components/main/initialValue4/label3_withdrawalRecord/withdrawalRecord.vue"
      ),
    // 营业日报表
    salesReport: () =>
      import(
        "./components/main/initialValue5/label1_salesReport/salesReport.vue"
      ),
    // 商品统计表
    brandST: () =>
      import("./components/main/initialValue5/label2_brandST/brandST.vue"),
    // 订单明细表
    orderDS: () =>
      import("./components/main/initialValue5/label3_orderDS/orderDS.vue"),
    // 收款统计表
    proceedsST: () =>
      import(
        "./components/main/initialValue5/label4_proceedsST/proceedsST.vue"
      ),
    // 收款明细表
    proceedsDS: () =>
      import(
        "./components/main/initialValue5/label5_proceedsDS/proceedsDS.vue"
      ),
    // 员工送货统计表
    empDeliveryST: () =>
      import(
        "./components/main/initialValue5/label6_empDeliveryST/empDeliveryST.vue"
      ),
    // 员工送货明细表
    empDeliveryDS: () =>
      import(
        "./components/main/initialValue5/label7_empDeliveryDS/empDeliveryDS.vue"
      ),
    // 金币明细表
    goldDS: () =>
      import("./components/main/initialValue5/label8_goldDS/goldDS.vue"),
    // 员工推广统计表
    empPromotionST: () =>
      import(
        "./components/main/initialValue5/label9_empPromotionST/empPromotionST.vue"
      ),
    // 记账统计表
    tallyST: () =>
      import("./components/main/initialValue5/label10_tallyST/tallyST.vue"),
    // 空桶统计表
    emptyBucketST: () =>
      import(
        "./components/main/initialValue5/label11_emptyBucketST/emptyBucketST.vue"
      ),
    // 押桶统计表
    caskST: () =>
      import("./components/main/initialValue5/label12_caskST/caskST.vue"),
    // 水票统计表
    waterTicketST: () =>
      import(
        "./components/main/initialValue5/label13_waterTicketST/waterTicketST.vue"
      ),
    // 商户资料
    merchantInformation: () =>
      import(
        "./components/main/initialValue6/label1_merchantInformation/merchantInformation.vue"
      ),
    // 用户资料
    userInformation: () =>
      import(
        "./components/main/initialValue6/label2_userInformation/userInformation.vue"
      ),
    // 会员资料
    memberInformation: () =>
      import(
        "./components/main/initialValue6/label3_memberInformation/memberInformation.vue"
      ),
    // 团队管理
    teamManage: () =>
      import(
        "./components/main/initialValue6/label4_teamManage/teamManage.vue"
      ),
    // 叫水提醒
    callWaterReminder: () =>
      import(
        "./components/main/initialValue6/label5_callWaterReminder/callWaterReminder.vue"
      ),
    // 评价管理
    appraiseManage: () =>
      import(
        "./components/main/initialValue6/label6_appraiseManage/appraiseManage.vue"
      ),
    // 导航管理
    navigationManage: () =>
      import(
        "./components/main/initialValue7/label1_navigationManage/navigationManage.vue"
      ),
    // 宣传素材
    propagandaMaterial: () =>
      import(
        "./components/main/initialValue7/label2_propagandaMaterial/propagandaMaterial.vue"
      ),
    // 链接管理
    linkManage: () =>
      import(
        "./components/main/initialValue7/label3_linkManage/linkManage.vue"
      ),
    // 经营管理
    management: () =>
      import(
        "./components/main/initialValue8/label1_management/management.vue"
      ),
    // 店铺资料
    storeInformation: () =>
      import(
        "./components/main/initialValue9/label1_storeInformation/storeInformation.vue"
      ),
    // 自提站点
    pickUpStation: () =>
      import(
        "./components/main/initialValue9/label2_pickUpStation/pickUpStation.vue"
      ),
    // 地图围栏
    mapFence: () =>
      import("./components/main/initialValue9/label3_mapFence/mapFence.vue"),
    // 角色管理
    roleManage: () =>
      import(
        "./components/main/initialValue9/label4_roleManage/roleManage.vue"
      ),
    // 员工管理
    staffManage: () =>
      import(
        "./components/main/initialValue9/label5_staffManage/staffManage.vue"
      ),
    // 运费模板
    freightTem: () =>
      import(
        "./components/main/initialValue9/label6_freightTem/freightTem.vue"
      ),
    // 策略设置
    policySetting: () =>
      import(
        "./components/main/initialValue9/label7_policySetting/policySetting.vue"
      ),
    // 供应商档案
    supplierRecord: () =>
      import(
        "./components/main/initialValue10/label1_supplierRecord/supplierRecord.vue"
      ),
    // 商品库存
    goodsStock: () =>
      import(
        "./components/main/initialValue10/label2_goodsStock/goodsStock.vue"
      ),
    // 采购入库
    procurementStorage: () =>
      import(
        "./components/main/initialValue10/label3_procurementStorage/procurementStorage.vue"
      ),
    // 空桶库存
    emptyBarrelInventory: () =>
      import(
        "./components/main/initialValue10/label4_emptyBarrelInventory/emptyBarrelInventory.vue"
      ),
    // 首页轮播图管理
    banner: () => import("./components/main/initialValue9/label8_banner/banner.vue"),
    // 操作日志
    log: () => import("./components/main/initialValue9/label9_log/log.vue"),
    // 推广员申请
    promoter: () => import("./components/main/initialValue6/label7_promoter/promoter.vue"),
    // 电子发票记录
    invoice: () => import("./components/main/initialValue6/label8_invoice/invoice.vue")
  },
  data() {
    return {
      myMains: "desktopVue",
      tabName: "",
      sameAside: "",
      // 登录状态
      isLogin: false,
    };
  },
  methods: {
    // 《路由跳转》
    tamClick(data) {
      switch (data) {
        case "电子发票记录":
          this.myMains = "invoice";
          break;
        case "推广员申请":
          this.myMains = "promoter";
          break;
        case "操作日志":
          this.myMains = "log";
          break;
        case "商品分类":
          this.myMains = "goodsCategoryVue";
          break;
        case "轮播图管理":
          this.myMains = "banner";
          break;
        case "品牌类型":
          this.myMains = "brandTypeVue";
          break;
        case "商品档案":
          this.myMains = "CommodityFileVue";
          break;
        case "水票管理":
          this.myMains = "waterRegulateVue";
          break;
        case "互动营销":
          this.myMains = "interactiveVue";
          break;
        case "全场活动":
          this.myMains = "overallActive";
          break;
        case "单品活动":
          this.myMains = "singleItem";
          break;
        case "优惠套餐":
          this.myMains = "discountPackage";
          break;
        case "优惠券":
          this.myMains = "discountCoupon";
          break;
        case "支付宝电子水票":
          this.myMains = "Alipay";
          break;
        case "二维码":
          this.myMains = "QRcode";
          break;
        case "自提空桶入库单":
          this.myMains = "emptyBarrel";
          break;
        case "水站进货订单":
          this.myMains = "waterStationPurchase";
          break;
        case "预售产品库存审核":
          this.myMains = "inventoryAudit";
          break;
        case "水站资产管理":
          this.myMains = "waterAtationAssets";
          break;
        case "其他收款记录":
          this.myMains = "otherCollectionRecords";
          break;
        case "水站进货统计表":
          this.myMains = "waterStationPST";
          break;
        case "水站进货明细表":
          this.myMains = "waterStationPL";
          break;
        case "水站水桶统计表":
          this.myMains = "waterStationBST";
          break;
        case "水站销售月报表":
          this.myMains = "waterStationMSS";
          break;
        case "预购库存统计表":
          this.myMains = "advancePurchaseIST";
          break;
        case "商品销售统计表":
          this.myMains = "commodityMST";
          break;
        case "空桶统计表":
          this.myMains = "emptyBarrelPST";
          break;
        case "收款统计表":
          this.myMains = "proceedsPST";
          break;
        case "收款月报表":
          this.myMains = "proceedsMRF";
          break;
        case "司机配送统计表":
          this.myMains = "driverDistributionPST";
          break;
        case "司机配送明细表":
          this.myMains = "driverDistributionPL";
          break;
        case "呼叫记录":
          this.myMains = "callLog";
          break;
        case "人工报单":
          this.myMains = "manualEntry";
          break;
        case "销售订单":
          this.myMains = "sellOrder";
          break;
        case "出库单":
          this.myMains = "srockOut";
          break;
        case "退桶单":
          this.myMains = "bucketReturn";
          break;
        case "收款记录":
          this.myMains = "collectionRecord";
          break;
        case "退款记录":
          this.myMains = "refundRecord";
          break;
        case "提现记录":
          this.myMains = "withdrawalRecord";
          break;
        case "营业日报表":
          this.myMains = "salesReport";
          break;
        case "商品统计表":
          this.myMains = "brandST";
          break;
        case "订单明细表":
          this.myMains = "orderDS";
          break;
        case "收款统计表 ":
          this.myMains = "proceedsST";
          break;
        case "收款明细表":
          this.myMains = "proceedsDS";
          break;
        case "员工送货统计表":
          this.myMains = "empDeliveryST";
          break;
        case "员工送货明细表":
          this.myMains = "empDeliveryDS";
          break;
        case "金币明细表":
          this.myMains = "goldDS";
          break;
        case "员工推广统计表":
          this.myMains = "empPromotionST";
          break;
        case "记账统计表":
          this.myMains = "tallyST";
          break;
        case "空桶统计表 ":
          this.myMains = "emptyBucketST";
          break;
        case "押桶统计表":
          this.myMains = "caskST";
          break;
        case "水票统计表":
          this.myMains = "waterTicketST";
          break;
        case "商户资料":
          this.myMains = "merchantInformation";
          break;
        case "用户资料":
          this.myMains = "userInformation";
          break;
        case "会员资料":
          this.myMains = "memberInformation";
          break;
        case "团队管理":
          this.myMains = "teamManage";
          break;
        case "叫水提醒":
          this.myMains = "callWaterReminder";
          break;
        case "评价管理":
          this.myMains = "appraiseManage";
          break;
        case "导航管理":
          this.myMains = "navigationManage";
          break;
        case "宣传素材":
          this.myMains = "propagandaMaterial";
          break;
        case "链接管理":
          this.myMains = "linkManage";
          break;
        case "经营情况":
          this.myMains = "management";
          break;
        case "店铺资料":
          this.myMains = "storeInformation";
          break;
        case "自提站点":
          this.myMains = "pickUpStation";
          break;
        case "地图围栏":
          this.myMains = "mapFence";
          break;
        case "角色管理":
          this.myMains = "roleManage";
          break;
        case "员工管理":
          this.myMains = "staffManage";
          break;
        case "运费模板":
          this.myMains = "freightTem";
          break;
        case "策略设置":
          this.myMains = "policySetting";
          break;
        case "供应商档案":
          this.myMains = "supplierRecord";
          break;
        case "商品库存":
          this.myMains = "goodsStock";
          break;
        case "采购入库":
          this.myMains = "procurementStorage";
          break;
        case "空桶库存":
          this.myMains = "emptyBarrelInventory";
          break;
        default:
          this.myMains = "desktopVue";
          break;
      }

    },

    asideItemClick(data) {
      this.tamClick(data);
      this.tabName = data;
    },
    tabContentDesk(data) {
      this.tamClick(data);
    },
    tabContent(data) {
      this.tamClick(data);
    },
    tabClose(data) {
      this.tamClick(data);
    },
    loginT(data) {
      this.isLogin = data;
    },
    reloadComponent() {
      bus.$on("reloadComponent", (data) => {
        this.myMains = data;
      });
    },
  },
  computed: {
    tabArr() {
      return [...new Set(this.tab)];
    },
  },
  created() { },
  mounted() {
    if (localStorage.getItem("token")) {
      this.isLogin = true;
    }
    if (sessionStorage.getItem("token")) {
      this.isLogin = true;
    }
    this.reloadComponent();
  },
  watch: {},
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}

.el-header {
  height: 44px !important;
}

.el-header,
.el-aside,
.el-main {
  padding: 0 !important;
}

.container {
  position: relative;
}

.aside {
  position: relative;
  left: 0;
  top: 0;
  min-height: calc(100vh - 44px);
}
</style>
