import qs from "qs";
import axios from "axios";
// axios.defaults.baseURL = "http://192.168.3.3/index.php"; //线下
const httpa = axios.create({
  baseURL: "http://192.168.3.3/index.php",
  headers: {
    token: localStorage.getItem("token") || sessionStorage.getItem("token"),
  },
});

const commercialId =
  localStorage.getItem("commercial_id") ||
  sessionStorage.getItem("commercial_id");

// 互动营销 -- initial1 - label5
const commonModule = {
  namespaced: true,
  state: {
    // 分页数据
    paging: {
      data: [],
      count: null,
    },
  },
  mutations: {
    setPagingDataValue(state, payload) {
      state.paging.data = payload;
    },
    setPagingCountValue(state, payload) {
      state.paging.count = payload;
    },
  },
  actions: {
    // 全部数据 -- 分页，搜索
    getPagingInfo({ commit }, payload) {
      httpa
        .post(
          payload.baseURL,
          qs.stringify({
            currentPage: payload.currentPage,
            currentLength: payload.currentPageTotal,
            commercial_id: commercialId,
          })
        )
        .then((res) => {
          commit("setPagingDataValue", res.data.data);
          commit("setPagingCountValue", res.data.count);
        });
    },
    miaoshaSerach({ commit }, payload = {}) {
      const { search, product } = payload;
      httpa
        .post(payload.baseURL, qs.stringify({ product_arr: product, search }))
        .then((res) => {
          commit("setPagingDataValue", res.data.data);
          commit("setPagingCountValue", res.data.count);
        });
    },
    searchPageInfo({ commit }, payload = {}) {
      const {
        search,
        firstTime,
        lastTime,
        status,
        currentPage,
        currentLength,
      } = payload;
      httpa
        .post(
          payload.baseURL,
          qs.stringify({
            search,
            firstTime,
            lastTime,
            status,
            currentPage,
            currentLength,
            commercial_id: commercialId,
          })
        )
        .then((res) => {
          commit("setPagingDataValue", res.data.data);
          commit("setPagingCountValue", res.data.count);
        });
    },
  },
};

export default { commonModule };
