<template>
  <div>
    <div class="breadcrumb" v-if="chooseCrumbData == 1">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item v-for="(item, index) in crumbs" :key="index">
          {{ item }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <el-button
        type="success"
        style="height: 36px"
        @click="reload"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>

    <div class="breadcrumb" v-if="chooseCrumbData == 2">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item v-for="(item, index) in crumbs" :key="index">
          {{ item }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../until/bus.js";
export default {
  props: ["crumbData", "chooseCrumbData", "reloadComponent"],
  data() {
    return {
      fullscreenLoading: false,
    };
  },
  mounted() {},
  computed: {
    crumbs() {
      return this.crumbData;
    },
  },
  methods: {
    reload() {
      bus.$emit("reloadComponent", this.reloadComponent);
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 2000);
    },
    closeClick() {
      this.$emit("close", 0);
    },
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
