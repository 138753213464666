<template>
  <div>
    <header class="navbar-wrapper">
      <div class="navbar-fixed-top">
        <div class="navbar-logo">
          <a href="#" class="navbar-logo-name">水便利</a>
          <span class="navbar-logo-label">v2.0</span>
        </div>

        <div class="navbar-userbar">
          <ul>
            <li>
              <a href="#" class="navbar-userbar-name">广州水便利</a>
            </li>
            <li>
              <div class="navbar-userbar-lis">
                <el-dropdown placement="bottom" @command="userbarSettingsClick">
                  <span class="el-dropdown-link">
                    {{ userbarName
                    }}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slots="dropdown">
                    <el-dropdown-item v-for="(item, index) in userbarSettings" :key="index" :command="item">{{ item
                    }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </li>
            <li>
              <el-badge is-dot class="userbarImg">
                <a href="#"><img class="img" :src="require('@/assets/headerAssets/公告.png')" alt="" />
                </a>
              </el-badge>
            </li>
            <li>
              <el-badge :value="userbarMessageNum" class="userbarImg userbarMessage">
                <a href="#"><img class="img" :src="require('@/assets/headerAssets/消息.png')" alt="#" /></a>
              </el-badge>
            </li>
            <li>
              <div class="userbarImg userbarBackground">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <a href="#"><img class="img" :src="require('@/assets/headerAssets/皮肤.png')" alt="#" /></a>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(navbarColor, index) in navbarColors" :key="index">{{ navbarColor
                    }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderVue",
  components: {},
  data() {
    return {
      userbarName: "***",
      userbarSettings: ["修改密码", "切换账号", "退出"],
      userbarMessageNum: 0,
      navbarColors: ["黑色 (默认)", "红色", "蓝色", "绿色", "黄色", "橙色"],
    };
  },
  methods: {
    userbarSettingsClick(value) {
      if (value == "退出") {
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
        window.location.reload();
      }
    },
  },
  mounted() { },
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.active {
  display: block;
}

.none {
  display: none;
}

.navbar-wrapper {
  background-color: #222222;

  .navbar-fixed-top {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar-logo {
      margin: 0 15px;

      .navbar-logo-name {
        font-size: 16px;
      }

      .navbar-logo-label {
        font-size: 12px;
        padding: 0 15px;
      }
    }

    .navbar-userbar {
      margin-right: 10px;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          padding: 0 10px;

          a {
            font-size: 14px;
          }

          .userbarImg {
            width: 25px;
            height: 25px;
          }

          .userbarMessage {
            .el-badge__content {
              margin-top: 3px;
              margin-right: 2px;
            }
          }

          .userbarBackground {
            width: 20px;
            height: 20px;

            /* 消除小三角 */
            .el-dropdown-link {
              .el-popper .popper__arrow {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }

  a,
  span {
    color: white;
  }
}

.img {
  width: 100%;
  height: 100%;
}
</style>
