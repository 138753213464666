<template>
  <div>
    <!-- <el-radio-group style="position: absolute;top: 50%;left: 182px;" v-model="isCollapse">
      <img v-if="isCollapse == true" @click="asideOff" style="width: 50px; height: 55px;"
        src="../assets/asideAssets/收入.png" alt="" />
      <img v-else-if="isCollapse == false" @click="asideOn" style="width: 50px; height: 55px;"
        src="../assets/asideAssets/展开.png" alt="">
    </el-radio-group> -->

    <el-menu :default-active="activeIndex" @select="asideItemClick" unique-opened background-color="#EEEEEE"
      class="el-menu-vertical-demo">
      <el-submenu v-for="asideItem in asideItems" :key="asideItem.id" :index="asideItem.name">
        <template slot="title">
          <span>{{ asideItem.name }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item v-for="label in asideItem.labels" :key="label" :index="label">{{ label }}</el-menu-item>
        </el-menu-item-group>
        <el-submenu :index="suboptionName" v-if="asideItem.flag == 0">
          <template slot="title">{{ suboptionName }}</template>
          <el-menu-item :index="suboption" v-for="suboption in suboptions" :key="suboption">{{ suboption }}</el-menu-item>
        </el-submenu>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import eventBus from "../until/bus.js";

export default {
  name: "AsideVue",
  components: {},
  data() {
    return {
      commercial_id: localStorage.getItem("commercial_id") ||
        sessionStorage.getItem("commercial_id"),
      isCollapse: true,
      // 侧边栏菜单数据
      asideItems: [
        {
          id: "1",
          name: "基础资料",
          labels: [
            "商品分类",
            "品牌类型",
            "商品档案",
            "水票管理",
            "互动营销",
            "全场活动",
            "单品活动",
            "优惠套餐",
            "优惠券",
            "二维码",
          ],
          flag: 1,
        },
        {
          id: "2",
          name: "水站进货管理",
          labels: [
            "自提空桶入库单",
            "水站进货订单",
            "预售产品库存审核",
            "水站资产管理",
            "其他收款记录",
          ],
          flag: 0,
        },
        {
          id: "3",
          name: "销售管理",
          labels: ["呼叫记录", "人工报单", "销售订单", "出库单", "退桶单"],
          flag: 1,
        },
        {
          id: "4",
          name: "进销存",
          labels: ["供应商档案", "商品库存", "采购入库", "空桶库存"],
          flag: 1,
        },
        {
          id: "5",
          name: "财务管理",
          labels: ["收款记录", "退款记录", "提现记录"],
          flag: 1,
        },
        {
          id: "6",
          name: "报表管理",
          labels: [
            "营业日报表",
            "商品统计表",
            "订单明细表",
            "收款统计表 ",
            "收款明细表",
            "员工送货统计表",
            "员工送货明细表",
            "金币明细表",
            "员工推广统计表",
            "记账统计表",
            "空桶统计表 ",
            "押桶统计表",
            "水票统计表",
          ],
          flag: 1,
        },
        {
          id: "7",
          name: "客户管理",
          labels: [
            "商户资料",
            "用户资料",
            "会员资料",
            "团队管理",
            "叫水提醒",
            "评价管理",
            "推广员申请",
            "电子发票记录"
          ],
          flag: 1,
        },
        {
          id: "8",
          name: "内容管理",
          labels: ["导航管理", "宣传素材", "链接管理"],
          flag: 1,
        },
        {
          id: "9",
          name: "经营管理",
          labels: ["经营情况"],
          flag: 1,
        },
        {
          id: "10",
          name: "系统管理",
          labels: [
            "店铺资料",
            "自提站点",
            "地图围栏",
            "角色管理",
            "员工管理",
            "运费模板",
            "策略设置",
            "轮播图管理",
            "操作日志",
          ],
          flag: 1,
        },
      ],

      suboptionName: "水站进货报表",
      suboptions: [
        "水站进货统计表",
        "水站进货明细表",
        "水站水桶统计表",
        "水站销售月报表",
        "预购库存统计表",
        "商品销售统计表",
        "空桶统计表",
        "收款统计表",
        "收款月报表",
        "司机配送统计表",
        "司机配送明细表",
      ],
      comValue: "",
      activeIndex: "我的桌面",
    };
  },
  methods: {
    // 侧边栏选项点击
    asideItemClick(data) {
      this.$emit("asideItemClick", data);
      eventBus.$emit("aside_tab_Class", data);
    },
  },
  computed: {},
  watch: {},
  mounted() {
    this.$nextTick(() => {
      eventBus.$on("tab_aside_item", (data) => {
        this.activeIndex = data;
      });
      eventBus.$on("tab_aside_activeNum", (data) => {
        this.activeIndex = data;
      });
    });
  },
};
</script>

<style scoped lang="less">
/deep/ .el-submenu__title {
  height: 37px !important;
  font-size: 14px !important;
  line-height: 35px !important;
}

/deep/ .el-menu-item {
  height: 32px !important;
  line-height: 32px !important;
}

/deep/ .el-menu-item-group__title {
  display: none !important;
}
</style>
