import axios from "axios";

axios.interceptors.request.use((config) => {
  if (localStorage.getItem("token")) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  } else {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["token"] = token;
    }
    return config;
  }
});

function pageInfo(parpms) {
  return new Promise((resolve, reject) => {
    axios
      .get( parpms)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => {
        reject();
      });
  });
}
// pageInfo = 



// let pageInfo = Vue.prototype.$http;
// console.log(Vue.$http);
export default {
  pageInfo,
};
