import Vue from "vue";
import Vuex from "vuex";
// import loginModule from "./modules/login";
import commonModules from "./modules/common";
import chooseModule from "./modules/bus";

const { commonModule } = commonModules;

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: {
    commonModule,
    chooseModule,
  },
});
